<template>
    <b-sidebar
        id="add-new-user-sidebar"
        :visible="isAddNewUserSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="val => $emit('update:is-add-new-user-sidebar-active', val)"
    >
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Shorten Link</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <!-- LongURL -->
                    <validation-provider #default="validationContext" name="LongURL" rules="required">
                        <b-form-group label="Long URL" label-for="longURL">
                            <b-form-input
                                id="longURL"
                                v-model="channelData.longURL"
                                :state="getValidationState(validationContext)"
                                trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Playlist ID -->
                    <!--          <validation-provider-->
                    <!--            #default="validationContext"-->
                    <!--            name="Playlist ID"-->
                    <!--            rules="required"-->
                    <!--          >-->
                    <!--            <b-form-group-->
                    <!--              label="Playlist ID"-->
                    <!--              label-for="playlistId"-->
                    <!--            >-->
                    <!--              <b-form-input-->
                    <!--                id="playlistId"-->
                    <!--                v-model="channelData.playlistId"-->
                    <!--                :state="getValidationState(validationContext)"-->
                    <!--                trim-->
                    <!--              />-->

                    <!--              <b-form-invalid-feedback>-->
                    <!--                {{ validationContext.errors[0] }}-->
                    <!--              </b-form-invalid-feedback>-->
                    <!--            </b-form-group>-->
                    <!--          </validation-provider>-->

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            block
                            variant="primary"
                            class="mr-2"
                            type="submit"
                        >
                            Add
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="button"
                            variant="outline-secondary"
                            @click="hide"
                        >
                            Cancel
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSidebar } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { ref } from "@vue/composition-api";
import { alphaNum, required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive"; //  import vSelect from 'vue-select'
import store from "@/store";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        //  vSelect,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isAddNewUserSidebarActive",
        event: "update:is-add-new-user-sidebar-active",
    },
    props: {
        isAddNewUserSidebarActive: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            required,
            alphaNum,
        };
    },
    setup(props, { emit }) {
        const blankchannelData = {
            longURL: "",
            // playlistId: "",
        };

        const channelData = ref(JSON.parse(JSON.stringify(blankchannelData)));
        const resetchannelData = () => {
            channelData.value = JSON.parse(JSON.stringify(blankchannelData));
        };

        const onSubmit = () => {
            store.dispatch("shortened/addShortlink", channelData.value).then(() => {
                emit("refetch-data");
                emit("update:is-add-new-user-sidebar-active", false);
            });
        };

        const { refFormObserver, getValidationState, resetForm } = formValidation(resetchannelData);

        return {
            channelData,
            onSubmit,

            refFormObserver,
            getValidationState,
            resetForm,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
