import { ref, watch } from "@vue/composition-api";
// import { title } from '@core/utils/filter'
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default function useUsersList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        {
            key: "id",
            label: "id",
            sortable: false,
        },
        {
            key: "link",
            sortable: false,
            label: "Link",
        },
        {
            key: "longUrl",
            sortable: false,
            label: "Long URL",
        },
        {
            key: "clickCount",
            sortable: false,
            label: "Click Count",
        },
        {
            key: "createDate",
            sortable: false,
            label: "Create Date",
        },
        {
            key: "actions",
        },
    ];
    const perPage = ref(10);
    const totalUsers = ref(null);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("createDate");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const tableLoading = ref(true);
    // const dataMeta = computed(() => {
    //     const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    //
    //     return {
    //         from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
    //         to: perPage.value * (currentPage.value - 1) + localItemsCount,
    //         of: totalUsers.value,
    //     };
    // });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, statusFilter], () => {
        refetchData();
    });

    const getLinks = (ctx, callback) => {
        store
            .dispatch("shortened/fetchLinks", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                // isClosed: statusFilter.value,
            })
            .then(response => {
                const { shortLinks } = response.data;
                callback(shortLinks);
                tableLoading.value = false;
                totalUsers.value = shortLinks.length;
                // totalUsers.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching channel list",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            });
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = role => {
        if (role === "subscriber") {
            return "primary";
        }

        if (role === "author") {
            return "warning";
        }

        if (role === "maintainer") {
            return "success";
        }

        if (role === "editor") {
            return "info";
        }

        if (role === "admin") {
            return "danger";
        }

        return "primary";
    };

    const resolveUserRoleIcon = role => {
        if (role === "subscriber") {
            return "UserIcon";
        }

        if (role === "author") {
            return "SettingsIcon";
        }

        if (role === "maintainer") {
            return "DatabaseIcon";
        }

        if (role === "editor") {
            return "Edit2Icon";
        }

        if (role === "admin") {
            return "ServerIcon";
        }

        return "UserIcon";
    };

    const resolveUserStatusVariant = isClosed => {
        if (isClosed) {
            return "warning";
        }

        if (!isClosed) {
            return "success";
        }

        return "primary";
    };

    return {
        getLinks,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        // dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        tableLoading,
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        refetchData,

        // Extra Filters
        statusFilter,
    };
}
